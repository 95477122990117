import { Brands } from '@/enums/brands';
import { T2ToyotaSubprodPattern } from '@digital-garage/commons-v2';

export default {
    MstcSubdomain: {
        [Brands.toyota]: 'smartpath',
        [Brands.lexus]: 'monogram',
    },
    defaultBuildsRoute: {
        [Brands.toyota]: '/configurator',
        [Brands.lexus]: '/build-your-lexus/#!/series',
    } as Record<Brands, string>,
    tierPatterns: {
        t1ToyotaPattern: '.toyota.com',
        t2ToyotaPattern: '.buyatoyota.com',
        t2ToyotaSubprodPattern: T2ToyotaSubprodPattern,
        t1LexusPattern: '.lexus.com',
    },
};
